import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col, Pagination } from "react-bootstrap";
import { useEffect, useState } from "react";
import EducationDetail from "./EducationDetail";
import Sidebar from "./Sidebar";
import HashLoader from "react-spinners/HashLoader";
import AccordionFilter from "./AccordionFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import Select from "react-select";

export default function AdminPage(props) {
  const {
    sidebarData,
    currentJamaatName,
    currentJamiaatName,
    sort,
    allJamiaatList,
    allJamaatIds,
    currentData,
    handleSort,
    EduStatus,
    downloadRecord,
    handleJamiaattChange,
    handleJamaatChange,
    handleGenderChange,
    currentGenderName,
    handleRequest,
    jamaatId,
    jamiatId,
    handleSubmitAge,
  } = props;
  const [color, setColor] = useState("#00336D");
  const [ageLimit, setAgeLimit] = useState({ lowerAge: 3, higherAge: 27 });
  const [optionsData, setOptionsData] = useState();
  const [jamaatOptionsData, setJamaatOptionsData] = useState();
  const [genderOptionsData, setGenderOptionsData] = useState();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [offset, setOffSet] = useState(0);

  const onLoad = {
    height: "100vh",
  };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const today = new Date();
  const month = monthNames[today.getMonth()];
  const currentDate = month + ", " + today.getFullYear();

  const handleAgeChnage = (e) => {
    let newAge = e.target.value;
    if (newAge < 3) newAge = 3;
    if (newAge > 27) newAge = 27;
    setAgeLimit({
      ...ageLimit,
      [e.target.name]: newAge,
    });
  };
  useEffect(() => {
    const genderOption = [
      { label: "All", value: "A" },
      { label: "Male", value: "M" },
      { label: "Female", value: "F" },
    ];
    setGenderOptionsData(genderOption);
  }, []);

  useEffect(() => {
    let offsetdata = (page - 1) * perPage;
    setOffSet(offsetdata);
  }, [page, perPage]);

  useEffect(() => {
    if (allJamiaatList && allJamiaatList.length > 0) {
      let optionsDataArr = allJamiaatList?.map((item) => {
        return { label: item.jamiaat, value: item.jamiaat_id };
      });
      setOptionsData(optionsDataArr);
    }
  }, [allJamiaatList]);
  useEffect(() => {
    if (allJamaatIds) {
      let optionsDataArr = allJamaatIds?.map((item) => {
        return { label: item.jamaat, value: item.jamaat_id };
      });
      setJamaatOptionsData(optionsDataArr);
    }
  }, [allJamaatIds]);

  useEffect(() => {
    setPage(1);
    currentData &&
      perPage &&
      setTotalPage(Math.ceil(currentData.length / perPage));
  }, [currentData, perPage]);

  const handlePrev = () => {
    page > 1 && setPage((prev) => prev - 1);
  };

  const handleNext = () => {
    page < totalPage && setPage((prev) => prev + 1);
  };
  const handleFirst = () => {
    page > 1 && setPage(1);
  };
  const handleLast = () => {
    totalPage && setPage(totalPage);
  };
  // const handlePage = (e) => {
  //   setPage(e.target.value)
  // }
  const location = useLocation();
  const isDeeniRoute = location.pathname === "/deeni";
  return (
    <>
      <Row
        style={{
          backgroundColor: "#E5E5E5",
          margin: "0px",
          ...(!sidebarData ? onLoad : ""),
        }}
      >
        <Col
          className="sidebarMedia mt-1"
          xs={3}
          style={{ backgroundColor: "#fff" }}
        >
          {sidebarData ? (
            <>
              <div className="m-4 primary-bg">
                <div className="primary-label">Students in Mauze</div>
                <div
                  className="mb-1 mt-2"
                  style={{ color: "white", fontSize: "0.85em" }}
                >
                  <strong>Age Group</strong>
                </div>
                <div className=" d-flex mb-1">
                  <input
                    type="number"
                    name="lowerAge"
                    value={ageLimit.lowerAge}
                    className="form-control input-get"
                    aria-label="Text input with checkbox"
                    onChange={handleAgeChnage}
                  />
                  <input
                    type="number"
                    name="higherAge"
                    value={ageLimit.higherAge}
                    className="form-control input-get"
                    aria-label="Text input with checkbox"
                    onChange={handleAgeChnage}
                  />
                  <button
                    type="button"
                    className="btn btn-secondary btn-get"
                    onClick={() =>
                      handleSubmitAge(ageLimit.lowerAge, ageLimit.higherAge)
                    }
                  >
                    Get data
                  </button>
                </div>

                <div className="dropdown mb-1">
                  <Select
                    options={optionsData}
                    value={optionsData?.find(
                      (item) => item.label === currentJamiaatName
                    )}
                    isClearable={false}
                    onChange={(e) => handleJamiaattChange(e.value, e.label)}
                  />
                </div>

                <div className="dropdown mb-1">
                  <Select
                    options={jamaatOptionsData}
                    value={jamaatOptionsData?.find(
                      (item) => item.label === currentJamaatName
                    )}
                    isClearable={false}
                    onChange={(e) => handleJamaatChange(e.value, e.label)}
                  />
                </div>
                <div
                  className="mb-1 mt-2"
                  style={{ color: "white", fontSize: "0.85em" }}
                >
                  <strong>Gender</strong>
                </div>
                <div className="dropdown mb-1">
                  <Select
                    options={genderOptionsData}
                    value={genderOptionsData?.find(
                      (item) => item.value === currentGenderName
                    )}
                    isClearable={false}
                    onChange={(e) => handleGenderChange(e.value, e.label)}
                  />
                </div>
                <Sidebar
                  sidebarData={sidebarData.Total_Student}
                  handleRequest={handleRequest}
                  EduStatus={EduStatus}
                />
                <Sidebar
                  sidebarData={sidebarData.Male_Student}
                  handleRequest={handleRequest}
                  EduStatus={EduStatus}
                />
                <Sidebar
                  sidebarData={sidebarData.Female_Student}
                  handleRequest={handleRequest}
                  EduStatus={EduStatus}
                />
                <Sidebar
                  sidebarData={sidebarData.Migrated_In}
                  handleRequest={handleRequest}
                  EduStatus={EduStatus}
                  theme={{
                    bg: "#ededed",
                    color: "#57774c",
                    activeBg: "#57774c",
                    activeColor: "#ededed",
                  }}
                  addClass="mt-3"
                />
              </div>

              <div className="m-4">
                <div className="sidebar-content">
                  <strong>Raza Status:</strong>
                </div>
                <Sidebar
                  sidebarData={sidebarData.Raza_Status}
                  handleRequest={handleRequest}
                  EduStatus={EduStatus}
                />
              </div>

              <div
                className={`m-4 ${
                  Array.isArray(sidebarData.Stream) &&
                  sidebarData.Stream.length > 0
                    ? ""
                    : "d-none"
                }`}
              >
                <div className="sidebar-content">
                  <strong>Streams / Marhala:</strong>
                </div>
                <Sidebar
                  sidebarData={sidebarData.Stream}
                  handleRequest={handleRequest}
                  EduStatus={EduStatus}
                />
              </div>

              <div className="m-4">
                <div className="sidebar-content">
                  <strong>Other Categories:</strong>
                </div>
                <Sidebar
                  sidebarData={sidebarData.main_menu}
                  handleRequest={handleRequest}
                  EduStatus={EduStatus}
                />
              </div>

              <div className="m-4">
                {isDeeniRoute ? (
                  <a
                    rel={"noreferrer"}
                    className="btn-download"
                    style={{ position: "static" }}
                    href={`https://talabulilm.com/profile/csvdownload.php?deeni&jamaat=${jamaatId}&jamiat=${jamiatId}&minAge=${ageLimit.lowerAge}&maxAge=${ageLimit.higherAge}`}
                    target="_blank"
                  >
                    {" "}
                    Download All Records
                  </a>
                ) : (
                  <a
                    rel={"noreferrer"}
                    className="btn-download"
                    style={{ position: "static" }}
                    href={`https://talabulilm.com/profile/csvdownload.php?jamaat=${jamaatId}&jamiat=${jamiatId}&minAge=${ageLimit.lowerAge}&maxAge=${ageLimit.higherAge}`}
                    target="_blank"
                  >
                    {" "}
                    Download All Records
                  </a>
                )}
              </div>
            </>
          ) : (
            <div className="loader-content">
              <HashLoader color={color} size={30} />
            </div>
          )}
        </Col>
        {sidebarData ? (
          <AccordionFilter
            sidebarData={sidebarData}
            handleRequest={handleRequest}
            EduStatus={EduStatus}
          />
        ) : (
          <div className="loader-content text-center mt-5 d-block d-sm-none">
            <HashLoader size={30} />
          </div>
        )}

        <Col xs={12} lg={9} className="main-content mt-1">
          <h3 className="page-title">
            Showing results for "{EduStatus}" as of {currentDate}
          </h3>
          <div className="explaination-box d-none d-sm-block show-less">
            <p>
              <strong>Note:</strong> All the data displayed on this page is
              fetched from the Araiz mumineen send to Hadrat Aaliyah for their
              Education, and through the Educational Profile where mumineen
              update their data. Any discrepancy in this data can be resolved
              through www.talabulilm.com/profile. Mumineen will be able to edit
              or remove any incorrect data. Currently, Amil saheb will not be
              able to delete any incorrect entries. But, Amil saheb can add
              data/survey of students whose data is incomplete through the
              Education Survey tab
            </p>
            {(EduStatus === "Migrated In for current education" ||
              EduStatus === "Migrated Out for current education") && (
              <p>
                The data displayed on this page consists of Student's who have
                migrated from other cities to the Cluster that your mauze
                belongs to. It cannot be determined that which insitute belongs
                to which Mohalla or Mauze, therefore Talabulilm has created
                Clusters of citites that are geographically very close to the
                insitutes. And all these insitutes are tagged with its
                respective clusters. The results currently displayed will be
                shown to all mawaze in your Cluster
              </p>
            )}
          </div>
          <a
            rel={"noreferrer"}
            className="btn-download"
            href={`${downloadRecord}`}
            target="_blank"
          >
            {" "}
            Download
          </a>
          <div>
            <span className="label">Sort By: </span>
            <button
              type="button"
              className={`btn  ${
                sort.field === "age" ? "btn-sort-active" : "btn-sort"
              }`}
              onClick={() => handleSort("age")}
            >
              Sort By Age{" "}
              {sort.direction === "asc" && sort.field === "age" ? (
                <FontAwesomeIcon icon={faArrowDown} />
              ) : sort.direction === "desc" && sort.field === "age" ? (
                <FontAwesomeIcon icon={faArrowUp} />
              ) : (
                ""
              )}
            </button>
            <button
              type="button"
              className={`btn ${
                sort.field === "name" ? "btn-sort-active" : "btn-sort"
              }`}
              onClick={() => handleSort("name")}
            >
              Sort By Name{" "}
              {sort.direction === "asc" && sort.field === "name" ? (
                <FontAwesomeIcon icon={faArrowDown} />
              ) : sort.direction === "desc" && sort.field === "name" ? (
                <FontAwesomeIcon icon={faArrowUp} />
              ) : (
                ""
              )}{" "}
            </button>
            <button
              type="button"
              className={`btn ${
                sort.field === "gender" ? "btn-sort-active" : "btn-sort"
              }`}
              onClick={() => handleSort("gender")}
            >
              Sort By Gender{" "}
              {sort.direction === "asc" && sort.field === "gender" ? (
                <FontAwesomeIcon icon={faArrowDown} />
              ) : sort.direction === "desc" && sort.field === "gender" ? (
                <FontAwesomeIcon icon={faArrowUp} />
              ) : (
                ""
              )}{" "}
            </button>
            <button
              type="button"
              className={`btn ${
                sort.field === "current_edu_course"
                  ? "btn-sort-active"
                  : "btn-sort"
              }`}
              onClick={() => handleSort("current_edu_course")}
            >
              Sort By Current Course{" "}
              {sort.direction === "asc" &&
              sort.field === "current_edu_course" ? (
                <FontAwesomeIcon icon={faArrowDown} />
              ) : sort.direction === "desc" &&
                sort.field === "current_edu_course" ? (
                <FontAwesomeIcon icon={faArrowUp} />
              ) : (
                ""
              )}{" "}
            </button>
            {currentData && currentData.length !== 0 && (
              <div style={{ marginLeft: "auto" }}>
                <Pagination style={{ justifyContent: "flex-end" }}>
                  <span onClick={() => handleFirst()}>
                    <Pagination.First />
                  </span>
                  <span onClick={() => handlePrev()}>
                    <Pagination.Prev />
                  </span>
                  <Pagination.Item>{`${page} / ${totalPage}`}</Pagination.Item>
                  <span onClick={() => handleNext()}>
                    <Pagination.Next />
                  </span>
                  <span onClick={() => handleLast()}>
                    <Pagination.Last />
                  </span>
                </Pagination>
              </div>
            )}
          </div>
          {currentData && currentData.length !== 0 ? (
            <EducationDetail
              currentData={currentData}
              offset={offset}
              perPage={perPage}
            />
          ) : currentData && currentData.length === 0 ? (
            <div className="loader-content">No data found ....</div>
          ) : (
            <div className="loader-content">
              <HashLoader color={color} size={78} />
            </div>
          )}
        </Col>
      </Row>
    </>
  );
}
