import React, { useEffect, useState } from "react";
import AdminPage from "./InstitudeAdmin";
import { Buffer } from "buffer";
import useFetch from "../../useFetch";
import { ToastContainer } from "react-toastify";
import MainNavbar from "../MainNavbar";
import MobileNavbar from "../MobileNavbar";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const getCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const getTokenFromCookie = () => {
  if (getCookie("user_its") === null) {
    // Live website
    window.location.replace(
      "https://www.its52.com/Login.aspx?OneLogin=MHB&tlbre=YWRtaW4udGFsYWJ1bGlsbS5jb20="
    );
    // test website
    // window.location.replace(
    //   "https://www.its52.com/Login.aspx?OneLogin=MHB&tlbre=aHR0cHM6Ly90ZXN0YWFtaWxzYWhlYi50YWxhYnVsaWxtLmNvbS8="
    // );
    return;
  }

  var username = getCookie("user_its")?.split(",")[0];
  var password = getCookie("ver");
  // localStorage.setItem("username", username);

  // var adminToken = localStorage.getItem("admin-token");
  // console.log('adminToken: ', adminToken);

  // let password = md5(`${50476733}tlbilm@12345678+515253`);
  const token = Buffer.from(`${username}:${password}`, "utf8").toString(
    "base64"
  );
  localStorage.setItem("admin-token", token);
  // token && setToken(token);
  return token;
};

export default function Institude() {
  let location = useLocation();
  const [sidebarData, setSideBarData] = useFetch();
  const [allJamaatList, setAllJamaatList] = useFetch();
  const [allJamiaatList, setAllJamiaatList] = useFetch();
  const [allInstitudeList, setAllInstitudeList] = useFetch();
  const [apiData, setApiData] = useFetch();
  const [headerData, setHeaderData] = useFetch();
  const [userFullName, setUserFullName] = useFetch();
  const [countryList, setCountryList] = useFetch();
  const [currentJamaatName, setCurrentJamaatName] = useState();
  const [currentJamiaatName, setCurrentJamiaatName] = useState();
  const [currentInstitudeName, setCurrentInstitudeName] = useState();
  const [EduStatus, setEduStatus] = useState(
    "Araz done for currently studying"
  );
  const [downloadRecord, setDownloadRecord] = useState(
    "?cmd=razastatus&raza_status=Araz%20done"
  );

  const [ageLimit, setAgeLimit] = useState({ lowerAge: 3, higherAge: 27 });
  const [currentJamiaatList, setCurrentJamiaatList] = useState();
  const [currentJamaatList, setCurrentJamaatList] = useState();
  const [currentInstitudeList, setCurrentInstitudeList] = useState();
  const [currentJamaatId, setCurrentJamaatId] = useState();
  const [currentGenderName, setCurrentGenderName] = useState("A");
  const [currentJamiaattId, setCurrentJamiaattId] = useState();
  const [currentInstitudeId, setCurrentInstitudeId] = useState();
  const [currentData, setCurrentData] = useState([]);
  const defaultSort = { field: "", direction: "" };
  const [sort, setSort] = useState(defaultSort);
  const [token, setToken] = useState(false);

  useEffect(() => {
    if (
      ageLimit &&
      currentJamaatId &&
      currentJamaatId !== "All" &&
      currentJamiaattId !== "All" &&
      currentInstitudeId !== "All"
    ) {
      setSideBarData(
        `profile/admin/adminFilters/${ageLimit.lowerAge}/${ageLimit.higherAge}/${currentGenderName}/${currentJamiaattId}/${currentJamaatId}`
      );
    } else if (ageLimit && currentJamiaattId && currentJamiaattId !== "All") {
      setSideBarData(
        `profile/admin/adminFilters/${ageLimit.lowerAge}/${ageLimit.higherAge}/${currentGenderName}/${currentJamiaattId}`
      );
    } else if (ageLimit) {
      setSideBarData(
        `profile/admin/adminFilters/${ageLimit.lowerAge}/${ageLimit.higherAge}/${currentGenderName}`
      );
    }
  }, [
    ageLimit,
    currentJamiaattId,
    currentJamaatId,
    currentInstitudeId,
    currentGenderName,
  ]);

  //institude detail
  useEffect(() => {
    if (location.pathname === "/institude") {
      setCountryList("araiz/user/countryDetails");
    }
  }, [location.pathname]);

  useEffect(() => {
    allJamiaatList &&
      allJamiaatList.length > 0 &&
      setCurrentJamiaatList(
        [{ id: "All", name: "All" }, ...allJamiaatList]?.map((item) => {
          return { jamiaat_id: item.id, jamiaat: item.name };
        })
      );
  }, [allJamiaatList]);

  useEffect(() => {
    if (sidebarData) {
      setSort(defaultSort);
      if (ageLimit && currentJamaatId && currentJamaatId !== "All") {
        handleRequest(
          `razaUserList/${ageLimit.lowerAge}/${ageLimit.higherAge}/${currentGenderName}/1/${currentJamiaattId}/${currentJamaatId}/Araz%20done`,
          "Araz done for currently studying",
          "?cmd=razastatus&raza_status=Araz%20done"
        );
      } else if (ageLimit && currentJamiaattId && currentJamiaattId !== "All") {
        handleRequest(
          `razaUserList/${ageLimit.lowerAge}/${ageLimit.higherAge}/${currentGenderName}/1/${currentJamiaattId}/Araz%20done`,
          "Araz done for currently studying",
          "?cmd=razastatus&raza_status=Araz%20done"
        );
      } else if (ageLimit) {
        handleRequest(
          `razaUserList/${ageLimit.lowerAge}/${ageLimit.higherAge}/${currentGenderName}/1/Araz%20done`,
          "Araz done for currently studying",
          "?cmd=razastatus&raza_status=Araz%20done"
        );
      }
    }
  }, [sidebarData]);

  useEffect(() => {
    if (currentJamiaatList && currentJamiaatList.length > 0) {
      const inititalData = allJamiaatList?.map((item) => {
        return { jamaat_id: item, jamaat: item };
      });
      // setMyTask(`profile/admin/taskList/${inititalData[0]?.jamaat_id}`);
      // setCurrentJamiaattId(inititalData[0]?.jamaat_id);
      setCurrentJamiaattId("All");
    }
  }, [currentJamiaatList]);

  useEffect(() => {
    let token = getTokenFromCookie();
    token && setToken(token);
    if (allJamaatList && allJamaatList.length > 0) {
      let newAllCityList = allJamaatList.map((item) => {
        return { jamaat_id: item.id, jamaat: item.city_name };
      });
      const inititalData = [
        { jamaat_id: "All", jamaat: "All" },
        ...newAllCityList,
      ];
      setCurrentJamaatList(inititalData);
    } else {
      setCurrentJamaatName("All");
      // setCurrentJamaatList([{ jamaat: "All", jamaat_id: "All" }]);
    }
  }, [allJamaatList]);

  useEffect(() => {
    let token = getTokenFromCookie();
    token && setToken(token);
    if (allInstitudeList && allInstitudeList.length > 0) {
      let newAllCityList = allInstitudeList.map((item) => {
        return { jamaat_id: item.id, jamaat: item.name };
      });
      const inititalData = [
        { jamaat_id: "All", jamaat: "All" },
        ...newAllCityList,
      ];
      setCurrentInstitudeList(inititalData);
    } else {
      setCurrentInstitudeName("All");
      setCurrentInstitudeList([{ jamaat: "All", jamaat_id: "All" }]);
      // setCurrentJamaatList([{ jamaat: "All", jamaat_id: "All" }]);
    }
  }, [allInstitudeList]);

  useEffect(() => {
    setHeaderData("profile/admin/details");
    setUserFullName("profile/");
    setAllJamiaatList("araiz/user/countryDetails");
  }, [token]);

  useEffect(() => {
    setCurrentData(apiData);
  }, [apiData]);

  useEffect(() => {
    if (currentJamiaattId) {
      const jamiaat = currentJamiaatList?.filter((item) =>
        item.jamiaat_id === currentJamiaattId ? item.jamiaat : ""
      );
      setCurrentJamiaatName(jamiaat?.[0]?.jamiaat);
      setCurrentJamaatList([{ jamaat: "All", jamaat_id: "All" }]);
    }
  }, [currentJamiaattId]);

  useEffect(() => {
    currentJamiaattId !== "All" &&
      currentJamiaattId &&
      setAllJamaatList(
        `araiz/user/cityDetailsByCountryISO/${currentJamiaattId}`
      );
  }, [currentJamiaattId]);

  useEffect(() => {
    if (currentJamaatId) {
      const jamaat = currentJamaatList?.filter((item) =>
        item.jamaat_id === currentJamaatId ? item.jamaat : ""
      );
      setCurrentJamaatName(jamaat?.[0]?.jamaat);
    } else {
      setCurrentJamaatName("All");
    }
  }, [currentJamaatId]);

  useEffect(() => {
    if (currentInstitudeId) {
      const jamaat = currentInstitudeList?.filter((item) =>
        item.jamaat_id === currentInstitudeId ? item.jamaat : ""
      );
      setCurrentInstitudeName(jamaat?.[0]?.jamaat);
    } else {
      setCurrentInstitudeName("All");
    }
  }, [currentInstitudeId]);

  const handleRequest = (verb, lable, downlaod) => {
    setApiData("");
    setSort(defaultSort);
    setApiData(`profile/admin/${verb}`);
    setEduStatus(lable);
    setDownloadRecord(downlaod);
    setCurrentData(apiData);
  };

  const handleSubmitAge = (lowerAgeLimit, higherAgeLimit) => {
    setAgeLimit({ lowerAge: lowerAgeLimit, higherAge: higherAgeLimit });
  };

  const handleSort = (field) => {
    let direction = sort.direction;
    if (field === sort.field) {
      direction = sort.direction === "asc" ? "desc" : "asc";
    } else {
      direction = "asc";
    }
    const sortedData = currentData?.sort((a, b) => {
      if (field === "age")
        return direction === "asc" ? a[field] - b[field] : b[field] - a[field];
      return direction === "asc"
        ? a[field].localeCompare(b[field])
        : b[field].localeCompare(a[field]);
    });
    setCurrentData(sortedData);
    setSort({ field: field, direction: direction });
  };

  const handleJamiaattChange = (jamiaatId, jamaat) => {
    setCurrentJamaatId();
    if (jamiaatId !== "All") {
      let jamiaat = allJamiaatList.find((item) => item.id == jamiaatId);
      setAllJamaatList(`araiz/user/cityDetailsByCountryISO/${jamiaat.iso2}`);
    } else {
      setCurrentJamaatList([{ jamaat: "All", jamaat_id: "All" }]);
      setCurrentInstitudeList([{ jamaat: "All", jamaat_id: "All" }]);
    }
    setCurrentJamiaattId(jamiaatId);
    setCurrentJamaatName("All");
    setCurrentInstitudeName("All");
  };

  const handleJamaatChange = (cityId, jamaat) => {
    if (cityId !== "All") {
      setCurrentJamaatId(cityId);
      setAllInstitudeList(`araiz/user/instituteDetails/${cityId}`);
    } else {
      setCurrentInstitudeId();
      setCurrentJamaatId();
      setCurrentInstitudeName("All");
      setCurrentInstitudeList([{ jamaat: "All", jamaat_id: "All" }]);
    }
  };

  const handleInstitudeChange = (cityId, jamaat) => {
    setCurrentInstitudeId(cityId);
  };

  const handleGenderChange = (genderId) => {
    setCurrentGenderName(genderId);
  };

  return (
    <>
      <MainNavbar
        headerData={headerData}
        currentJamaatName={currentJamaatName}
        userFullName={userFullName}
        ageLimit={ageLimit}
      />
      <MobileNavbar headerData={headerData} />
      <AdminPage
        sidebarData={sidebarData}
        currentJamiaatName={currentJamiaatName}
        currentJamaatName={currentJamaatName}
        currentInstitudeName={currentInstitudeName}
        currentData={currentData}
        sort={sort}
        handleJamiaattChange={handleJamiaattChange}
        handleJamaatChange={handleJamaatChange}
        handleInstitudeChange={handleInstitudeChange}
        allJamiaatList={currentJamiaatList}
        allJamaatIds={currentJamaatList}
        allInstitudeList={currentInstitudeList}
        EduStatus={EduStatus}
        downloadRecord={downloadRecord}
        handleRequest={handleRequest}
        handleSort={handleSort}
        jamaatId={currentJamaatId}
        jamiatId={currentJamiaattId}
        handleSubmitAge={handleSubmitAge}
        handleGenderChange={handleGenderChange}
        currentGenderName={currentGenderName}
      />
      <ToastContainer />
    </>
  );
}
